// import / define custom variable here


// breakpoint list
$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1200px;
$xxl: 1600px;
$fullhd: 1920px;
$fullhdplus: 1921px;
$fourk: 3840px; 

// breakpoint value
$bpval: (sm, md, lg, xl, xxl, fullhd, fullhdplus, fourk);

@mixin app-breakpoint($type, $fallback: false, $parent: true) {
  @if $type == sm {
    @media (min-width: $sm) {
      @content;
    }
  }
  @if $type == md {
    @media (min-width: $md) {
      @content;
    }
  }
  @if $type == lg {
    @media (min-width: $lg) {
      @content;
    }
  }
  @if $type == xl {
    @media (min-width: $xl) {
      @content;
    }
  }
  @if $type == xxl {
    @media (min-width: $xxl) {
      @content;
    }
  }
  @if $type == fullhd {
    @media (min-width: $fullhd) {
      @content;
    }
  }
  @if $type == fullhdplus {
    @media (min-width: $fullhdplus) {
      @content;
    }
  }
  @if $type == fourk {
    @media (min-width: $fourk) {
      @content;
    }
  }
  @if $fallback {
    @if $parent {
      .#{$fallback} & {
        @content; 
      }
    } @else {
      .#{$fallback} {
        @content;
      }
    }
  }
}

@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Lobster&display=swap');
.font-bebas {
  font-family: 'Bebas Neue', cursive;
}
.font-lobster {
  font-family: 'Lobster', cursive;
}

// import bootstrap and default variables
@import '~bootstrap/scss/bootstrap.scss';

$paddingscreenmobile: 12px;
$paddingscreen: 24px;
$adsheight: 200px;

.App {
  position: relative;
  padding: $paddingscreenmobile;
  background: #fff;
  width: 100%;
  height: auto;
  @include app-breakpoint(md) {
    padding: $paddingscreen;
    height: 100vh;
  }
}

.page-content {
  background: #ccc;
  position: relative;
  width: 100%;
  height: auto;
  @include app-breakpoint(md) {
    overflow: hidden;
    height: calc(80vh - #{$paddingscreen});
  }
  @include app-breakpoint(lg) {
    height: calc(80vh - #{$paddingscreen});
  }
  @include app-breakpoint(xl) {
    height: calc(80vh - #{$paddingscreen});
  }
  @include app-breakpoint(fullhd) {
    height: calc(80vh - #{$paddingscreen});
  }
}

.page-ads {
  background: #fff;
  position: relative;
  width: 100%;
  margin-top: $paddingscreenmobile;
  overflow: hidden;
  height: calc(20vh - 2 * #{$paddingscreenmobile});
  @include app-breakpoint(md) {
    margin-top: $paddingscreen;
    height: calc(20vh - 2 * #{$paddingscreen});
  }
  @include app-breakpoint(lg) {
    height: calc(20vh - 2 * #{$paddingscreen});
  }
  @include app-breakpoint(xl) {
    height: calc(20vh - 2 * #{$paddingscreen});
  }
  @include app-breakpoint(fullhd) {
    height: calc(20vh - 2 * #{$paddingscreen});
  }
}
.col-home-greetings {
  margin-top: 0;
  @include app-breakpoint(md) {
    margin-top: -130px;
  }
}
.text-selamat-datang {
  font-size: 34px;
  text-shadow: 1px 1px rgba(0,0,0, 0.8);
  @include app-breakpoint(md) {
    font-size: 54px;
  }
  @include app-breakpoint(fullhdplus) {
    font-size: 96px;
  }
}
.text-kalimatan-timur {
  font-size: 40px;
  text-shadow: 1px 1px rgba(0,0,0, 0.8);
  @include app-breakpoint(md) {
    font-size: 72px;
  }
  @include app-breakpoint(lg) {
    font-size: 108px;
  }
  @include app-breakpoint(fullhdplus) {
    font-size: 144px;
  }
}

.menu-navigation {
  width: 100%;
  position: absolute;
  bottom: 0;
  .menu-wrapper {
    align-items: flex-start;
    justify-content: center;
    padding-bottom: 30px;
    display: none;
    @include app-breakpoint(md) {
      display: flex;
    }
    .nav-item {
      width: 130px;
      text-align: center;
      position: relative;
      text-decoration: none;
      padding: 0 8px;
      color: #000;
      font-weight: 700;
      transition: all 0.2s ease-in-out;
      // text-shadow: 2px 2px rgba(0,0,0, 0.8);
      text-shadow: 1px 1px 2px #000;
      &:hover {
        text-decoration: none;
        opacity: 0.85;
      }
      @include app-breakpoint(md) {
        width: 75px;
        font-size: 10px;
      }
      @include app-breakpoint(lg) {
        width: 95px;
        font-size: 10px;
      }
      @include app-breakpoint(xl) {
        width: 80px;
        font-size: 10px;
      }
      @include app-breakpoint(fullhdplus) {
        width: 170px;
        font-size: 20px;
      }
      .icon {
        width: 95px;
        height: 95px;
        background: #ccc;
        margin: 0 auto;
        border-radius: 8%;
        box-shadow: 0 3px 10px grey;
        margin-bottom: 10px;
        background-size: contain;
        background-position: center;
        @include app-breakpoint(md) {
          width: 50px;
          height: 50px;
        }
        @include app-breakpoint(lg) {
          width: 75px;
          height: 75px;
        }
        @include app-breakpoint(xl) {
          width: 55px;
          height: 55px;
        }
        @include app-breakpoint(fullhdplus) {
          width: 125px;
          height: 125px;
        }
      }
      .nav-item-text {
        color: white;
      }
    }
  }
}
.menu-navigation-mobile {
  position: fixed;
  left: 0;
  width: 100%;
  height: 100%;
  bottom: 100%;
  background-color: rgba(0,0,0, 0.4);
  &.active {
    bottom: 0;
    .menu-mobile-wrapper {
      bottom: 0;
    }
  }
  .menu-mobile-wrapper {
    position: fixed;
    width: 100%;
    bottom: -400px;
    text-align: center;
    background-color: rgba(0,0,0, 0.8);
    padding: 16px;
    display: flex;
    flex-direction: column;
    transition: all 0.2s ease-in-out;
    .nav-item-mobile {
      text-decoration: none;
      color: white;
      &:hover {
        opacity: 0.85;
      }
    }
    @include app-breakpoint(md) {
      display: none;
    }
  }
}
.button-bars-mobile {
  position: absolute;
  top: 24px;
  right: 24px;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  color: white;
  @include app-breakpoint(md) {
    display: none;
  }
}

.carousel-gallery {
  .wrap-img {
    position: relative;
    img {
      margin: 0 auto;
    }
  }
  .slick-prev {
    z-index: 100;
    left: 15px;
    width: 30px;
    height: 30px;
    &::before {
      font-size: 30px;
    }
    @include app-breakpoint(md) {
      left: 25px;
      width: 50px;
      height: 50px;
      &::before {
        font-size: 50px;
      }
    }
  }
  .slick-next {
    z-index: 100;
    right: 15px;
    width: 30px;
    height: 30px;
    &::before {
      font-size: 30px;
    }
    @include app-breakpoint(md) {
      right: 25px;
      width: 50px;
      height: 50px;
      &::before {
        font-size: 50px;
      }
    }
  }
  .caption-wrapper {
    text-align: center;
    padding: 4px;
    position: absolute;
    width: 100%;
    bottom: 0;
    background-color: rgba(0,0,0, 0.4);
    color: white;
    font-size: 12px;
    p {
      margin: 0;
    }
    @include app-breakpoint(fullhdplus) {
      font-size: 28px;
    }
  }
}

.video-responsive {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
}

.video-responsive iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}

.page-title {
  font-size: 36px;
  line-height: 36px;
  text-shadow: 1px 1px rgba(0,0,0, 0.8);
  @include app-breakpoint(md) {
    font-size: 64px;
    line-height: 64px;
  }
  @include app-breakpoint(fullhdplus) {
    font-size: 108px;
    line-height: 108px;
  }
}
.page-content-wrapper {
  padding: 16px;
  border-radius: 8px;
  color: white;
  font-size: 12px;
  p {
    margin: 0;
  }
  @include app-breakpoint(xxl) {
    font-size: 16px;
  }
  @include app-breakpoint(fullhdplus) {
    font-size: 28px;
  }
}

.section-ads, .carousel-ads,
.carousel-ads .slick-slider, .carousel-ads .slick-list, .carousel-ads .slick-track {
  height: 100%;
}
.wrap-img-ads img {
  margin: 0 auto;
  height: calc(20vh - 2 * #{$paddingscreenmobile});
  @media screen and (orientation:portrait) {
    width: 100%;
  }
  @include app-breakpoint(md) {
    height: calc(20vh - 2 * #{$paddingscreen}); 
  }
}

.social-icon-list {
  position: absolute;
  bottom: 0px;
  right: 8px;
  display: flex;
  @include app-breakpoint(md) {
    flex-direction: column;
    bottom: unset;
    top: 50%;
    transform: translateY(-50%);
  }
  .link-social-media {
    margin-left: 8px;
    margin-bottom: 8px;
    background-color: white;
    border-radius: 50%;
    box-shadow: 0 3px 10px grey;
    @include app-breakpoint(fullhdplus) {
      width: 72px !important;
      height: 72px !important;
      margin-bottom: 22px;
    }
  }
}

.list-agenda-item {
  margin-bottom: 16px;
  border-bottom: 1px solid #ccc;
  &--name {
    font-size: 16px; 
    font-weight: 700;
    margin-bottom: 8px;
    @include app-breakpoint(fullhdplus) {
      font-size: 28px;
    }
  }
  &--date {
    text-transform: capitalize;
    font-size: 13px;
    font-weight: 700;
    color: #666;
    @include app-breakpoint(fullhdplus) {
      font-size: 20px;
    }
  }
  &--desc {
    font-size: 13px;
    margin-bottom: 8px;
    @include app-breakpoint(fullhdplus) {
      font-size: 20px;
    }
  }
  &:last-child {
    border-bottom: 0;
  }
}


.list-investor-item {
  margin-bottom: 16px;
  // border: 1px solid #ccc;
  // padding: 12px;
  // border-radius: 16px;
  &--name {
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 8px;
    @include app-breakpoint(fullhdplus) {
      font-size: 28px;
    }
  }
  &--desc {
    font-size: 12px;
    @include app-breakpoint(fullhdplus) {
      font-size: 24px;
    }
  }
}

.btn {
  @include app-breakpoint(fullhdplus) {
    font-size: 28px;
  }
}